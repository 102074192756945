.SketchfabViewer{
    flex-basis: 70%;
}

.debug{
    font-size: small;
}

.selected{
    background-color: lightgreen;
}

.unselected{
    background-color: gray;
}